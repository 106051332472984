<script>
import {defineComponent} from 'vue'
import ButtonComponent from "@/components/ButtonComponent.vue";

export default defineComponent({
    name: "HomePage",
    components: {ButtonComponent, }
})
</script>

<template>
    <div class="page page-home">
        <div class="page__body">
            <div>
                <div class="page__content">
                    Добро пожаловать <br>
                    в Клуб PROфессионалов "МоиГлаза"!
                </div>
            </div>
        </div>
        <div class="page__bottom">
            <ButtonComponent
                text="Начать"
                @click="$router.push({name: 'Authorization'})"
            ></ButtonComponent>
        </div>
    </div>
</template>

<style scoped lang="scss">
.app {
    .page-home {
        .page__content {
            background-color: white;
        }
    }
    &--theme-black {
        .page-home {
            .page__content {
                background-color: #262628;
            }
            .page__bottom{
                background-color: #262628;
            }
        }
    }
}
.page-home {
    display: grid;
    grid-template-rows: 1fr auto;
}
.page__body {
    padding: 40px 20px;
    height: 100%;
}
.page__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #003595;
    border-radius: 20px 20px 0 0;
    height: 210px;
    svg {
        width: auto;
        max-width: 80%;
    }
}
.page__content {
    padding: 25px 10px;
    line-height: inherit;
    border-radius: 20px;
}
.page__bottom {
    margin-top: 20px;
    padding: 6px 20px 60px;
}
</style>
