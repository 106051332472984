<template>
	<div class="phone-wrapper">
		<label class="label">Номер телефона</label>
		<div class="input-wprapper">
			<input
					class="input"
					v-model="phone"
					:class="error && 'error'"
					v-mask="'+ ###############'"
					ref="inputPhone"
					:disabled="disabled"
			>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InputPhone',
	props: {
		error: {type: Boolean, default: false},
		disabled: {type: Boolean, default: false},
		value: {type: String, default: ''},
	},
	data() {
		return {
			phone: ''
		}
	},
	watch: {
		phone() {
			this.$emit('input', String(this.phone.replace(/\D+/g, '')))
		},
		value() {
			this.phone = this.value
		}
	},
	mounted() {
		this.$refs.inputPhone.focus()
		this.phone = this.value
	}
}
</script>

<style lang="scss" scoped>
.app {
	&--theme-black {
		.phone-wrapper {
			.input-wprapper{
				&:before {
					background-color: var(--tg-theme-bg-color);
				}
				&:after {
					background-color: var(--tg-theme-bg-color);
				}
			}
		}
	}
}
	.phone-wrapper {
		width: 380px;
		max-width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		margin: 0 auto;

	}
	.input-wprapper {
		position: relative;
		.input {
			border:none;
			border-bottom: 1px solid #b3b3b3;
			font-size: 40px;
			border-radius: 0;
			padding: 0;
			height: 47px;
			font-family: 'Roboto';
			color: #00ADEE;
			letter-spacing: 2px;
			background-color: transparent;
			outline: none;
			width: 100%;
			outline: none;
			box-sizing: border-box;

			&:focus {
				border-color: #8b8b8b;
			}
		}
		//&:before {
		//	content: '';
		//	position: absolute;
		//	bottom: -1px;
		//	left: 50px;
		//	background-color: white;
		//	width: 20px;
		//	height: 2px;
		//	z-index: 999;
		//}
		//&:after {
		//	content: '';
		//	position: absolute;
		//	bottom: -1px;
		//	left: 154px;
		//	background-color: white;
		//	width: 20px;
		//	height: 2px;
		//	z-index: 999;
		//}
	}

	.label {
		font-weight: 400;
		font-size: 30px;
		line-height: 23px;
		margin-bottom: 30px;
	}
	.error {
		border-color: #EB6161;
	}
</style>
